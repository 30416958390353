import React from 'react';
import { Container, Col, Row, Form } from "react-bootstrap";
import { Link } from 'gatsby';
import Select from 'react-select';
import './assets/styles/_index.scss';

const ContactForm = () => {

    const options = [
        { value: 'sales', label: 'Sales' },
        { value: 'rent', label: 'Rent' }
    ]

    return (
        <>
            <Form.Group className="form-field-group" controlId="">
                <Form.Label>Name*</Form.Label>
                <Form.Control type="text" placeholder="Name*" />
            </Form.Group>

            <Form.Group className="form-field-group" controlId="">
                <Form.Label>Email Address*</Form.Label>
                <Form.Control type="email" placeholder="Email Address*" />
            </Form.Group>

            <Form.Group className="form-field-group" controlId="">
                <Form.Label>Telephone Number</Form.Label>
                <Form.Control type="text" placeholder="Telephone Number" />
            </Form.Group>

            <Form.Group className="form-field-group" controlId="">
                <Form.Label>Department</Form.Label>
                <div className="custom-selectbox-form selectbox-dropdown">
                    <Select 
                        options={options}
                        isSearchable={false}
                        classNamePrefix={"select-opt"}
                        className="form-control"
                        placeholder="Sales"
                        components={{ DropdownIndicator:() => <i className="icon icon-dropdown-arrow"></i>, IndicatorSeparator:() => null }}
                    />
                </div>
            </Form.Group>

            <Form.Group className="form-field-group" controlId="">
                <Form.Label>Enquiry</Form.Label>
                <Form.Control as="textarea" placeholder="Write your enquiry here..." rows={5} />
            </Form.Group>

            <div className="contact-terms">By clicking Submit Enquiry, you agree to our <Link to="#">Terms & Conditions</Link> and <Link to="#">Privacy Policy.</Link></div>

            <Link to="#" className="btn btn-primary-outline w-100">Submit Enquiry</Link>

            <div className="contact-terms">* required field</div>
        </>
    )
}

export default ContactForm