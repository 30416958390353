import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import Breadcrumb from "../components/Breadcrumb/Breadcrumb"
import ContactForm from "../components/ContactForm/ContactForm"
import ContactCard from "../components/ContactCard/ContactCard"

const ContactFormPage = () => {
    return (
        <>
            <Header />

            <Breadcrumb />

            <Container className="contact-form-wrapper">
                <Row>
                    <Col lg={7}>
                        <div className="contact-title">
                            <h4>Contact Sandfords</h4>
                            <p>Please complete the form below and we will respond to your query shortly.</p>
                        </div>

                        <ContactForm />
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={4} className="mt-lg-0 mt-5">
                        <ContactCard />
                    </Col>
                </Row>
            </Container>

            <Footer />
        </>
    )
}

export default ContactFormPage