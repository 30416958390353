import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from 'gatsby';
import './assets/styles/_index.scss';

const Breadcrumb = () => {
    return (
        <section className="breadcrumb-wrapper d-flex align-items-center">
            <Container>
                <Row>
                    <Col>
                        <div className="d-flex">
                            <Link to="#">Home</Link>
                            <div className="vertical-divider"></div>
                            <Link to="#">Contact</Link>
                            <div className="vertical-divider"></div>
                            <div className="current-page">Contact Sandfords</div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Breadcrumb