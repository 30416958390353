import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import { Link } from 'gatsby';
import './assets/styles/_index.scss';
import contactImg from './assets/images/img.png';

const ContactCard = () => {
    return (
        <section className="contact-card-wrapper">
            <Row>
                <Col lg={12} md={6} className="contact-card-mobile top-card">
                    <img src={contactImg} className="img-fluid" />
                </Col>
                <Col lg={12} md={6} className="contact-card-mobile bottom-card">
                    <div className="contact-card-box">
                        <div className="contact-card">
                            <div className="contact-card-title">Office Address</div>
                            <p>213-215 Gloucester Place, London NW1 6BU</p>
                        </div>

                        <div className="contact-card">
                            <div className="contact-card-title">Phone</div>
                            <a href={`tel: +44 (0)20 7723 9988`}> +44 (0)20 7723 9988</a>
                        </div>

                        <div className="contact-card">
                            <div className="contact-card-title">Opening Hours</div>
                            <p>Mon-Fri: 9am - 6pm <br /> Saturday: By appointment  |  Sunday: Closed</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
    )
}

export default ContactCard